import '../app/components/App/typescript';
import './useFactory';

import ReactOnRails from 'react-on-rails';

import Glapp from './Main';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  Glapp,
});
